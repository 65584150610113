<template lang="pug">
  include /mixins
  +b.hd-modal-success
    +e.icon.SVG(xmlns='http://www.w3.org/2000/svg' width='46' height='44' viewbox='0 0 46 44' fill='none')
      g(opacity='0.99')
        path(d='M46 21.6731C46 27.4211 43.5768 32.9338 39.2635 36.9983C34.9501 41.0627 29.1 43.3461 23 43.3461C19.9796 43.3461 16.9888 42.7856 14.1983 41.6964C11.4078 40.6072 8.87229 39.0108 6.73654 36.9983C4.6008 34.9857 2.90663 32.5965 1.75077 29.967C0.594912 27.3375 0 24.5192 0 21.6731C5.56518e-07 15.925 2.42321 10.4124 6.73655 6.3479C11.0499 2.28341 16.9 -2.9134e-08 23 0C29.1 0 34.9501 2.28341 39.2635 6.3479C43.5768 10.4124 46 15.925 46 21.6731Z' fill='#66B07A')
      path(d='M9.51807 23.1056L18.6385 31.6999L35.3594 10.2142' stroke='white' stroke-width='2')

    +e.text.P {{ info.modalText }}

</template>

<script>
export default {
  name: 'Success',
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
