import VueI18n from 'vue-i18n'
import VModal from 'vue-js-modal';
export function install(Vue) {
  Vue.use(VueI18n)
  Vue.use(VModal, {
    dynamic: true,
    dialog: true,
    dynamicDefaults: {
      transition: 'nice-modal-fade',
    },
  })
}

export default { install }
