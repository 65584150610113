import './public-path'

import Vue from 'vue'
import I18n from 'vue-i18n'

import { install } from '@/install'
import { createApp } from '@/app'

import './styles/index.sass'
import '@utils/events'
import '@/js/validation'

import appComponents from './js/components'

import {
  required, confirmed, email,
  min, min_value as minValue, max, max_value as maxValue,
  ext, length,
} from 'vee-validate/dist/rules'
import {
  extend,
} from 'vee-validate'

Vue.config.productionTip = false

Vue.use(install)
Vue.use(appComponents)

const { app } = createApp({
  Vue,
  I18n,
})

app.$mount('#app')

extend('required', { ...required })
extend('email', { ...email })
