const tagsTrigger = () => {
  const tabs = [...document.getElementsByClassName('js-tab')]
  tabs.forEach(element => {
    element.addEventListener('click', e => {
      e.preventDefault()
      const tab = e.target
      const tabId = tab.getAttribute('data-tab')
      const tabContent = document.getElementById(tabId)
      const tabContents = [...document.getElementsByClassName('js-tab-content')]
      tabs.forEach(el => el.classList.remove('is-active'))
      tab.classList.add('is-active')
      tabContents.forEach(content => content.classList.remove('is-active'))
      tabContent.classList.add('is-active')
    })
  })
}

export { tagsTrigger }
