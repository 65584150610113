export const logoBlocked = () => {
  /* eslint-disable prefer-destructuring */
  const main = document.getElementById('app')
  const language = window.language
  const location = ['/', `/${language}/`]
  location.forEach(loc => {
    if (loc === window.location.pathname) {
      main.style.minHeight = '0px'
      const BLOCKED_LINKS = Array.from(document.getElementsByClassName('logo-link'))
      BLOCKED_LINKS.forEach(element => {
        element.classList.add('blocked')
      })
    }
  })
}
