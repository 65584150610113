<template lang="pug">
  include /mixins
  +b.hd-modal
    //- +b.close-popup.A(
    //-   href='',
    //-   @click.prevent='$emit("close")'
    //- ) &times;
    +e.head
      +e.title.P {{ info.title }}
    +e.content
      +e.text {{ info.text }}
      +e.form
        +b.VALIDATION-OBSERVER(
          ref="validator",
          v-slot="{ valid }",
          tag="form",
        )
          ui-loader(v-if='isLoad')
          +b.g-row.--align_center.--appearance_spaced.--space_2md.--space_lg-xl
            +b.g-cell.g-cols--12
              validation-provider(
                :rules="'required'",
                v-slot="{ errors }"
                v-model='form.email',
                name='email'
                mode="lazy"
              )
                div
                  .relative
                    +b.error-label--top.LABEL(v-if="errors[0]") {{ errors[0] }}
                +b.d-control-input.INPUT(
                  :rules="'required|email'",
                  v-model='form.email',
                  name='email'
                  type='email'
                  id='email'
                  placeholder='Email'

                )
            +b.g-cell.g-cols--12
              +b.hd-button.BUTTON(
                style='margin-top: 25px'
                type='submit'
                :disabled='isLoad',
                id='emailsub'
                @click.prevent='prepareData(valid)'
              ) {{ info.bnt_text }}

</template>

<script>

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin'

import { getCookie } from '@utils/cookies'

import SuccessModal from './Success.vue'

export default {
  name: 'Subscription',
  mixins: [FormMixin],
  props: {
    info: {},
  },
  data() {
    return {
      form: {
        email: '',
      },
      non_field_errors: '',
    }
  },
  methods: {
    async prepareData() {
      const valid = (await this.$refs.validator.validate())
      if (!valid) return
      this.submit(valid, { 
        ...this.form,
        type: this.info.id,
      })
    },
    send(data) {
      return fetch('/api/v1/subscription/create/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRFToken': getCookie('csrftoken'),
        },
        body: JSON.stringify(data),
      })
        .then(({ data: resData }) => {
          this.form.email = ''
          this.$refs.validator.$el.reset()
          this.$refs.validator.reset()
          this.$emit('close')
          this.$emit('success')
          this.$modal.show(SuccessModal, {
            info: {
              modalText: this.info.message,
            },
            height: 'auto',
            classes: ['defaultModal'],
            adaptive: true,
            scrollable: true,
          })
        })
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.non_field_errors = parsed.non_field_errors
      this.$refs.validator.setErrors(parsed)
    },
  },
}
</script>
