// /* eslint-disable prefer-rest-params */
// import {
//   AnswerMessage,
// } from '@utils/submit'

const messageGetter = x => x.message

export function defaultValidatorErrorsParser(errors) {
  return Object.keys(errors).reduce((acc, x) => {
    acc[x] = errors[x].map(messageGetter)
    return acc
  }, {})
}

export default {
  data() {
    return {
      data: {},
      isLoad: false,
    }
  },
  mounted() {
    if (this.$refs.validator && this.$refs.validator.$el) {
      this.$refs.validator.$el.addEventListener('blur', () => {
        this.$refs.validator.reset()
      }, true)
    }
  },
  beforeDestroy() {
    if (this.$refs.validator) {
      this.$refs.validator.$el.removeEventListener('blur', () => {
        this.$refs.validator.reset()
      }, true)
    }
  },
  methods: {
    catchFormErrors(promise) {
      return promise.catch(e => {
        const clientError = 400
        const serverError = 500
        const tooManyRequestsError = 429
        console.log(e);
        if (e.status === tooManyRequestsError) {
          const message = {
            messages: [
              {
                title: this._('Запрос был проигнорирован.'),
                text: this._('Разрешено не более одного запроса в минуту'),
              },
            ],
          }
          // AnswerMessage(message, this)
        }
        console.log(e.status);
        if (!e.status || clientError > e.status || serverError <= e.status) {
          this.$notify({
            type: 'error', // 'error', 'warning'
            title: this._('Внутренняя ошибка сервера'),
            text: this._('Наши специалисты уже работают над этим'),
          })
        }

        if (!this.updateValidator) {
          return Promise.reject(e)
        }
        console.dir(e);
        return e.json().then(body => {
          const errors = {}

          body.errors.forEach(error => {
            if ('request' === error.domain && error.state) {
              Object.assign(errors, error.state)
            }
          })

          this.updateValidator(errors)

          return e
        })
      })
    },
    submit(valid, data) {
      const defaultArguments = [
        'valid',
        'data',
      ]
      if (!valid) {
        return Promise.reject()
      }

      this.isLoad = true
      const customArgs = Array.from(arguments)
      let count = 0
      const arg = customArgs.reduce((acc, obj, index) => {
        if (!defaultArguments[index]) {
          acc[count] = arguments[index]
          count++
        }
        return acc
      }, {})
      return this.catchFormErrors(this.send(data, arg))
        .finally(() => {
          this.isLoad = false
        })
    },
  },
}
